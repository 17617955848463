import * as Warning from "../../assets/warning-levels";

type WarningLevelsType = Record<string, { uri: string }>;

export const WarningLevels: WarningLevelsType = {
  "0_0": { uri: Warning.levels_0_0 },
  "0_1": { uri: Warning.levels_0_1 },
  "0_2": { uri: Warning.levels_0_2 },
  "0_3": { uri: Warning.levels_0_3 },
  "0_4": { uri: Warning.levels_0_4 },
  "0_5": { uri: Warning.levels_0_5 },
  "1_0": { uri: Warning.levels_1_0 },
  "1_1": { uri: Warning.levels_1_1 },
  "1_2": { uri: Warning.levels_1_2 },
  "1_3": { uri: Warning.levels_1_3 },
  "1_4": { uri: Warning.levels_1_4 },
  "1_5": { uri: Warning.levels_1_5 },
  "2_0": { uri: Warning.levels_2_0 },
  "2_1": { uri: Warning.levels_2_1 },
  "2_2": { uri: Warning.levels_2_2 },
  "2_3": { uri: Warning.levels_2_3 },
  "2_4": { uri: Warning.levels_2_4 },
  "2_5": { uri: Warning.levels_2_5 },
  "3_0": { uri: Warning.levels_3_0 },
  "3_1": { uri: Warning.levels_3_1 },
  "3_2": { uri: Warning.levels_3_2 },
  "3_3": { uri: Warning.levels_3_3 },
  "3_4": { uri: Warning.levels_3_4 },
  "3_5": { uri: Warning.levels_3_5 },
  "4_0": { uri: Warning.levels_4_0 },
  "4_1": { uri: Warning.levels_4_1 },
  "4_2": { uri: Warning.levels_4_2 },
  "4_3": { uri: Warning.levels_4_3 },
  "4_4": { uri: Warning.levels_4_4 },
  "4_5": { uri: Warning.levels_4_5 },
  "5_0": { uri: Warning.levels_5_0 },
  "5_1": { uri: Warning.levels_5_1 },
  "5_2": { uri: Warning.levels_5_2 },
  "5_3": { uri: Warning.levels_5_3 },
  "5_4": { uri: Warning.levels_5_4 },
  "5_5": { uri: Warning.levels_5_5 },
  above: { uri: Warning.levels_above },
  below: { uri: Warning.levels_below },
  middle: { uri: Warning.levels_middle },
};
